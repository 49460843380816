<template>
  <div>
      <h3>Add Todo</h3>
      <div class="add">
          <form class="form-group" @submit.prevent="onSubmit">
            <input type="text" v-model="title" placeholder=" Add Todo..." aria-describedby="helpId">
            <input type="submit" value="Submit" >
          </form>
      </div>
  </div>
</template>

<script>
    import { mapActions } from 'vuex'
    export default {
        name:'AddTodo',
        data(){
            return {
                title:''
            }
        },
        methods:{
            ...mapActions(['addTodo']),
            onSubmit(){
                if(this.title === ''){
                    return alert('You must provide a title')
                }
                this.addTodo(this.title)

            }
        }
    }
</script>

<style scoped>
form {
  display: flex;
}
input[type="text"] {
  flex: 10;
  padding: 10px;
  border: 1px solid #41b883;
  outline: 0;
}
input[type="submit"] {
  flex: 2;
  background: #41b883;
  color: #fff;
  border: 1px #41b883 solid;
  cursor: pointer;
}
</style>