import axios from 'axios'

const state = {
    todos: []
}

const getters = {
    allTodos: (state) => {return state.todos}
}

const actions = {
    async fetchTodos({ commit }){
        const response = await axios.get('https://jsonplaceholder.typicode.com/todos')

        commit('setTodos',response.data)
    },
    async addTodo({ commit }, title){
        const response =  await axios.post(
            'https://jsonplaceholder.typicode.com/todos',
            {
                title,
                completed:false
            }
        )

        commit('newTodo',response.data)
    },
    async deleteTodo({ commit }, id){
        await axios.delete(
            `https://jsonplaceholder.typicode.com/todos/${id}`            
        )
    
        commit('deleteTodo',id)
        
    }, 
    // eslint-disable-next-line no-unused-vars
    async filterTodos({ commit }, e){
        // Get selected number
        const limit = parseInt(e.target.options[e.target.selectedIndex].innerText)
        
        const response = await axios.get(`https://jsonplaceholder.typicode.com/todos?_limit=${limit}`)

        commit('setTodos',response.data)

    },
    async updateTodo({ commit }, updTodo){
        console.log(updTodo)
        const obj = {
            id: updTodo.id,
            title: updTodo.title,
            completed: !updTodo.complete
        }
        console.log(obj)
        const response = await axios.put(`https://jsonplaceholder.typicode.com/todos/${updTodo.id}`,obj)

        console.log(response)

        commit('updTodo',response.data)

    }
}

const mutations = {
    setTodos:(state, todos) => (state.todos = todos),
    newTodo: (state, todo) => state.todos.unshift(todo),
    deleteTodo: (state, id) => state.todos = state.todos.filter((t) => t.id !== id),
    updTodo: (state, obj) => {

        // Get the position of an object 
        const index = state.todos.findIndex(todo=> todo.id  == obj.id);
        console.log(index)
        // Do something it does exist
        if(index !== -1){
            state.todos.splice(
                    index, // the array
                    1, // the number of elements to be removed/replaced
                    obj // the element to replace
                 )
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}